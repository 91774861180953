/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-ijj26f --style3 --full" anim={""} name={"intro"} animS={"5"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--102 lh--1 mt--08" style={{"maxWidth":750}} content={"<span style=\"color: white;\">32nd International Economic Conference</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 fs--20" content={"Schedule"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"[[UNIsecntioname12]]-1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--2 flex--center" anim={"4"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":692}} content={"The World's Largest Meeting Of Experts."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":910}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized. "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":1019}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/154/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/154/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/154/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/154/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/154/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/154/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"[[UNIsecntioname12]]-2"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s3 --center el--2 flex--center" anim={"5"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/154/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/154/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/154/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/154/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/154/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/154/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":692}} content={"<span style=\"color: var(--color-dominant);\">Schedule</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":910}} content={"<span style=\"color: var(--color-dominant);\">17 June 2021 — Conference Opening<br>18 June 2021 — Main Speakers<br>19 June 2021 — Guest Speakers<br>20 June 2021 — Audience Questions<br>21 June 2021 — Conference End</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-ijj26f pb--60 pt--60" name={"contact"} layout={"l1"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: white;\">We look forward to seeing you!</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 fs--20" content={"RSVP"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>32nd International Economic Conference</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn4" content={"Schedule"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box lh--16" content={"<span style='color: var(--color-dominant)'>722 Finch Street, Asbury Park, NJ 07712<br>info@vase-stranky.com<br>510-851-4014</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":449}} content={"<span style='color: var(--color-dominant)'>Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them.<br>Created with <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}